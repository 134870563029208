document.addEventListener('DOMContentLoaded', () => {
  addModalAttributes()
})

function addModalAttributes() {
  const modalbuttons = document.querySelectorAll('.modal-job')
  modalbuttons.forEach((modalbutton) => {
    modalbutton.setAttribute('data-bs-toggle', 'modal')
    modalbutton.setAttribute('data-alpha-required-modules', '{"bsModal":{},"cf7Effect":{}}')
    modalbutton.setAttribute('data-alpha-loaded-modules', 'all')
  })
}
